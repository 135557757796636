{
  "name": "velasuite",
  "version": "0.1.1",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "build": "ng build",
    "watch": "ng build --watch --configuration development",
    "test": "ng test",
    "lint": "ng lint",
    "lint:fix": "ng lint --fix",
    "p:verify": "prettier --config ./.prettierrc --list-different \"src/{app,environments}/**/*{.ts,.html,.scss}\"",
    "p:fix": "prettier --config ./.prettierrc --write \"src/{app,environments}/**/*{.ts,.html,.scss}\""
  },
  "private": true,
  "engines": {
    "node": ">=18.20.4"
  },
  "dependencies": {
    "@ag-grid-community/angular": "^32.2.2",
    "@ag-grid-community/client-side-row-model": "^32.2.2",
    "@ag-grid-community/core": "^32.2.2",
    "@ag-grid-community/styles": "^32.2.2",
    "@angular-eslint/schematics": "^18.4.0",
    "@angular/animations": "^18.2.9",
    "@angular/common": "^18.2.9",
    "@angular/compiler": "^18.2.9",
    "@angular/core": "^18.2.9",
    "@angular/forms": "^18.2.9",
    "@angular/localize": "^18.2.9",
    "@angular/platform-browser": "^18.2.9",
    "@angular/platform-browser-dynamic": "^18.2.9",
    "@angular/router": "^18.2.9",
    "@azure/msal-angular": "3.0.23",
    "@azure/msal-browser": "3.23.0",
    "@fortawesome/fontawesome-pro": "^6.4.2",
    "@fortawesome/fontawesome-svg-core": "^6.4.0",
    "@fortawesome/pro-light-svg-icons": "^6.4.0",
    "@fortawesome/pro-regular-svg-icons": "^6.4.0",
    "@fortawesome/pro-solid-svg-icons": "^6.4.0",
    "@ng-bootstrap/ng-bootstrap": "17.0.1",
    "@ng-select/ng-select": "13.9.1",
    "@types/bootstrap": "^5.2.7",
    "@types/node": "20.2.5",
    "bootstrap": "5.2.3",
    "chart.js": "^4.3.0",
    "chartjs-plugin-datalabels": "^2.2.0",
    "date-fns": "^2.30.0",
    "daypilot-pro-angular": "https://npm.daypilot.org/daypilot-pro-angular/trial/2024.4.6180.tar.gz",
    "ng2-charts": "^6.0.1",
    "ngx-toastr": "19.0.0",
    "rxjs": "~7.8.0",
    "tslib": "2.3.0",
    "zone.js": "0.14.10"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.2.10",
    "@angular/cli": "~18.2.10",
    "@angular/compiler-cli": "^18.2.9",
    "@ngrx/eslint-plugin": "^16.2.0",
    "@ngx-translate/core": "^15.0.0",
    "@ngx-translate/http-loader": "^8.0.0",
    "@types/hammerjs": "^2.0.40",
    "@types/jasmine": "~4.3.0",
    "@typescript-eslint/eslint-plugin": "5.59.7",
    "@typescript-eslint/parser": "5.59.7",
    "eslint": "^8.40.0",
    "eslint-config-prettier": "^9.0.0",
    "eslint-plugin-prettier": "^5.0.1",
    "jasmine-core": "~4.5.0",
    "karma": "~6.4.0",
    "karma-chrome-launcher": "~3.1.0",
    "karma-coverage": "~2.2.0",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.0.0",
    "prettier": "^3.0.3",
    "prettier-eslint": "^16.1.1",
    "typescript": "5.5.4"
  }
}
